/* Weather: Expandable block
-------------------------------------------------- */

#expandable-weather-block .weather-now-container {
    padding: 10px 0;
}

#expandable-weather-block .weather-right-now-icon {
    font-size: 100px;
    margin: 0 30px 0 10px;
    line-height: normal;
}

#expandable-weather-block .weather-right-now-head {
     margin-bottom: 0;
}

#expandable-weather-block .weather-right-now-temp {
    font-size: 40px;
    line-height: 48px;
    font-weight: bold;
}

#expandable-weather-block .weather-right-now-details .pull-left:first-child {
    margin: 0 20px 0 5px;
}
#expandable-weather-block .weather-right-now-details {
    font-size: 13px;
}

#expandable-weather-block .weather-right-now-details li span {
    font-weight: bold;
}

#expandable-weather-block .weather-right-now-details li span.text-uppercase {
    font-weight: normal;
}

#expandable-weather-block .weather-right-now-extended h4,
#expandable-weather-block .weather-right-now-extended h5 {
    margin-bottom: 5px;
}

#expandable-weather-block .weather-right-now-extended h3 {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-transform: none;
    color: #333333;
    line-height: 140%;
    line-height: 25.2px;
    word-break: break-word;
    margin: 0;
}

#expandable-weather-block .weather-index-alerts {
    margin-bottom: 10px;
}

#expandable-weather-block .temp-condition-wrap .weather-right-now-temp {
    padding-right: 15px;
}

#expandable-weather-block .weather-hourly-condition {
    font-size: 45px; 
}

#expandable-weather-block .weather-index-alerts .pull-left,
#expandable-weather-block .weather-index-alerts .pull-right{
    font-size: 14px !important;
}

#expandable-weather-block .weather-right-now .weather-hourly-time {
    font-size: 12px;
}

#expandable-weather-block .weather-right-now .weather-hourly-temp {
    font-size: 18px;
    font-weight: bold;
    padding-top: 0px;
    padding-bottom: 5px;
}

#expandable-weather-block .weather-right-now-container {
    grid-template-columns: 100%;
    display: grid;
    justify-content: space-between;
    text-align: left;
}

#expandable-weather-block .weather-hourly-container  {
    grid-template-columns: repeat(5, 1fr);
    display: grid;
    display: -ms-grid;
    -ms-grid-columns: 20% 20% 20% 20% 20%;
    -ms-grid-rows: 1;
    justify-content: space-between;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
}

#expandable-weather-block .weather-right-now-container .temp-condition-wrap{
    display: flex;
    margin-bottom: 10px;
}

#expandable-weather-block b {
    display: none;
}

#expandable-weather-block .block-links {
    text-align: center;
}

#expandable-weather-block .position-0 {
    -ms-grid-column: 1; 
    -ms-grid-row: 1;
}

#expandable-weather-block .position-1 {
    -ms-grid-column: 2; 
    -ms-grid-row: 1;
}

#expandable-weather-block .position-2 {
    -ms-grid-column: 3; 
    -ms-grid-row: 1;
}

#expandable-weather-block .position-3 {
    -ms-grid-column: 4; 
    -ms-grid-row: 1;
}

#expandable-weather-block .position-4 {
    -ms-grid-column: 5; 
    -ms-grid-row: 1;
}

@media only screen and (max-width : 425px) {

   #expandable-weather-block b {
       display: inline-block;
       color: #9b9b9b;
       position: relative;
       top: -25px;
       border-top: 12px dashed;
       border-right: 12px solid transparent;
       border-left: 12px solid transparent;
       float: right;
   }

   #expandable-weather-block .weather-radar-container {
       margin-left:-999999px;
       margin-top:-300px;
       visibility: hidden;
   }

   #expandable-weather-block .weather-hourly-container {
      display: none;
   }

   #expandable-weather-block .weather-hourly-container.weather-info-hidden, 
   #expandable-weather-block .weather-radar-container.weather-info-hidden {
       margin-left:0;
       margin-top:0;
       visibility: visible;
   }

   #expandable-weather-block .weather-hourly-container.weather-info-hidden {
       display: grid;
   }

}

@media screen and (max-width: 767px) and (min-width: 426px){

    #expandable-weather-block .expandable-wrap > div > div.wrap {
        display: grid;
        grid-template-columns: 30% 70%;
    }

    #expandable-weather-block .weather-right-now-container .temp-condition-wrap {
        display: grid;
        border-bottom: none;
    }

}